import React, { useEffect, useState } from "react";
import { Accordion, Button, Table, Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
import { getExamMarks } from "../../axios/services/userService";
import "./Exams.css";
import { FiFilter } from "react-icons/fi";

const Exams = ({ enrollmentId }) => {
  const [examMarks, setExamMarks] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedDateRange, setSelectedDateRange] = useState(""); // State to store selected date range

  useEffect(() => {
    const fetchExamMarks = async () => {
      const { examMarks } = await getExamMarks(enrollmentId);
      setExamMarks(examMarks);
    };
    fetchExamMarks();
  }, [enrollmentId]);

  const isEmptyData = (data) => {
    return (
      data.length === 0 ||
      (data.length === 1 && Object.keys(data[0]).length === 0)
    );
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFilterApply = () => {
    // Implement your filter logic here based on selectedDateRange
    console.log("Filter applied with date range:", selectedDateRange);
    setShowModal(false); // Close the modal after applying filter
  };

  return (
    <>
      <div className="exam-mark-section">
        {/* <Button variant="secondary" className="filter-button" onClick={handleShowModal}>
          <FiFilter className="filter-icon"/>
          Filter Results
        </Button> */}

        {/* Modal for Filtering */}
        {/* <Modal show={showModal} onHide={handleCloseModal} className="modal">
          <Modal.Header closeButton className="modal-header-center">
            <Modal.Title>All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h5>Date Range</h5>
            <div>
              <Button
                variant={selectedDateRange === "Last 7 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 7 Days")}
                className="mb-2 date-range-button"
              >
                Last 7 Days
              </Button>
              <Button
                variant={selectedDateRange === "Last 30 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 30 Days")}
                className="mb-2 date-range-button"
              >
                Last 30 Days
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 date-range-button"
              >
                Last 90 Days
              </Button>
            </div>
            <h5 className="mt-4">Exam Type</h5>
            <div>
              <Button
                variant={selectedDateRange === "Last 7 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 7 Days")}
                className="mb-2 exam-type-button"
              >
                Midterm
              </Button>
              <Button
                variant={selectedDateRange === "Last 30 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 30 Days")}
                className="mb-2 exam-type-button"
              >
                Final
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 exam-type-button"
              >
                Quiz
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 exam-type-button"
              >
                All
              </Button>
            </div>
            <h5 className="mt-4">Subjects</h5>
            <div>
              <Button
                variant={selectedDateRange === "Last 7 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 7 Days")}
                className="mb-2 subjects-button"
              >
                Maths
              </Button>
              <Button
                variant={selectedDateRange === "Last 30 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 30 Days")}
                className="mb-2 subjects-button"
              >
                Science
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 subjects-button"
              >
                English
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 subjects-button"
              >
                History
              </Button>
              <Button
                variant={selectedDateRange === "Last 90 Days" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("Last 90 Days")}
                className="mb-2 subjects-button"
              >
                All
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button className="modal-reset-button" variant="secondary" onClick={handleCloseModal}>
              Reset
            </Button>
            <Button className="modal-apply-filter" variant="primary" onClick={handleFilterApply}>
              Apply Filter
            </Button>
          </Modal.Footer>
        </Modal> */}

        <div className="exam-mark-table">
          <Accordion defaultActiveKey="">
            {isEmptyData(examMarks) ? (
              <span>
                Dear Student, your data import is still in process. Please check
                back later after a few days.
              </span>
            ) : (
              examMarks.map((exam, index) => {
                const date = Object.keys(exam)[0];
                const results = exam[date].results;
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{date}</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Subjects</th>
                            <th>Marks Earned</th>
                            <th>Total Marks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results.map((result, i) => (
                            <tr key={i}>
                              <td>{result?.subject}</td>
                              <td>{result?.student_mark}</td>
                              <td>{result?.exam_total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Exams;
