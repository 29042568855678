import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Row } from "react-bootstrap";
import {
  getUserData,
  updateUserDetails,
} from "../../axios/services/userService";
import { toast } from "react-toastify";
import "./UserProfile.css";
import { useNavigate } from "react-router-dom";

const UserProfile = ({ courseDetails }) => {
  const [userDetails, setUserDetails] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState({});
  const studentId = localStorage.getItem("studentId");
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await getUserData(studentId);
      setUserDetails(data);
      setInitialValues(data);
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (userDetails.first_name && !/^[A-Za-z]+$/.test(userDetails.first_name))
      newErrors.first_name = "First name must only contain letters";
    if (userDetails.last_name && !/^[A-Za-z]+$/.test(userDetails.last_name))
      newErrors.last_name = "Last name must only contain letters";
    if (
      userDetails.student_email_id &&
      !/\S+@\S+\.\S+/.test(userDetails.student_email_id)
    )
      newErrors.student_email_id = "Invalid email";
    if (userDetails.pincode && !/^\d{6}$/.test(userDetails.pincode))
      newErrors.pincode = "Invalid pincode";
    if (
      userDetails.guardian_1_name &&
      !/^[A-Za-z]+$/.test(userDetails.guardian_1_name)
    )
      newErrors.guardian_1_name = "Guardian name must only contain letters";
    if (
      userDetails.guardian_1_phone &&
      !/^\d{10}$/.test(userDetails.guardian_1_phone)
    )
      newErrors.guardian_1_phone =
        "Guardian mobile must be a valid 10-digit number";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error("Please provide valid details in the form");
      return;
    }

    const changes = {};
    for (let key in userDetails) {
      if (userDetails[key] !== initialValues[key]) {
        changes[key] = userDetails[key];
      }
    }
    const res = await updateUserDetails(changes, studentId);
    if (res.success) {
      setUserDetails(res.updatedDetails);
      setInitialValues(res.updatedDetails);
      toast.success(res.message);
    } else {
      toast.error("Error occurred. Try again");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("studentId");
    navigate("/");
  };

  return (
    <>
      <div className="container">
        <div className="profile-page-wrapper">
          <div className="profile-page">
            <div className="logout-section">
              <p className="logout-button" onClick={handleLogout}>Click here to logout</p>
            </div>
            <h2>MY PROFILE</h2>
            <Form onSubmit={handleSubmit} style={{ paddingBottom: "10px" }}>
              <Row>
                <Form.Group
                  controlId="first_name"
                  className="col-sm-12 col-md-6 mt-3"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    name="first_name"
                    value={userDetails?.first_name || ""}
                    onChange={handleChange}
                    isInvalid={!!errors.first_name}
                    required
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="last_name"
                  className="col-sm-12 col-md-6 mt-3"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    name="last_name"
                    value={userDetails?.last_name || ""}
                    isInvalid={!!errors.last_name}
                    onChange={handleChange}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group controlId="student_mobile_number" className="mt-3">
                <Form.Label>Mobile Number</Form.Label>
                {/* <div className="mb-3"> */}
                {/* <InputGroup.Text id="basic-addon1">+91</InputGroup.Text> */}
                <Form.Control
                  type="tel"
                  placeholder="Enter your mobile number"
                  name="student_mobile_number"
                  value={userDetails?.student_mobile_number || ""}
                  onChange={handleChange}
                  aria-label="student_mobile_number"
                  aria-describedby="basic-addon1"
                  // required
                  disabled
                />
                {/* </div> */}
              </Form.Group>

              <Form.Group controlId="student_email_id" className="mt-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="student_email_id"
                  value={userDetails?.student_email_id || ""}
                  isInvalid={!!errors.student_email_id}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.student_email_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Form.Group
                  controlId="city"
                  className="col-sm-12 col-md-6 mt-3"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your city"
                    name="city"
                    value={userDetails?.city || ""}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>

                <Form.Group
                  controlId="district_name"
                  className="col-sm-12 col-md-6 mt-3"
                >
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your district"
                    name="district_name"
                    value={userDetails?.district_name || ""}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Form.Group controlId="pincode" className="mt-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your pincode"
                  name="pincode"
                  value={userDetails?.pincode || ""}
                  isInvalid={!!errors.pincode}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.pincode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="state_name" className="mt-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your state"
                  name="state_name"
                  value={userDetails?.state_name || ""}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="country" className="mt-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  name="country"
                  value={userDetails?.country || ""}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="guardian_1_name" className="mt-3">
                <Form.Label>Guardian Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter guardian name"
                  name="guardian_1_name"
                  value={userDetails?.guardian_1_name || ""}
                  isInvalid={!!errors.guardian_1_name}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.guardian_1_name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="guardian_relationship_with_student"
                className="mt-3"
              >
                <Form.Label>Relationship with Guardian</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter guardian relationship"
                  name="guardian_relationship_with_student"
                  value={userDetails?.guardian_relationship_with_student || ""}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="guardian_1_phone" className="mt-3 mb-4">
                <Form.Label>Guardian Mobile</Form.Label>
                {/* <div className="mb-3"> */}
                {/* <InputGroup.Text id="basic-addon1">+91</InputGroup.Text> */}
                <Form.Control
                  type="tel"
                  placeholder="Enter guardian phone number"
                  name="guardian_1_phone"
                  value={userDetails?.guardian_1_phone || ""}
                  isInvalid={!!errors.guardian_1_phone}
                  onChange={handleChange}
                  disabled
                  aria-label="guardian_1_phone"
                  aria-describedby="basic-addon1"
                />
                {/* </div> */}
                <Form.Control.Feedback type="invalid">
                  {errors.guardian_1_phone}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Button
                variant="success"
                type="submit"
                className="btn mt-3"
                disabled
              >
                SAVE
              </Button> */}
            </Form>
            <hr />
            <h2>COURSE DETAILS</h2>
            <div className="course-details-form">
              <Form
                // onSubmit={handleSubmit}
                className="course-details-form"
                style={{ paddingBottom: "120px" }}
              >
                <Row className="form-data">
                  <Form.Group
                    controlId="name"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Enrollment ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Enrollment ID"
                      name="name"
                      value={courseDetails?.name}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="enrollment_date"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Enrollment Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your enrollment date"
                      name="enrollment_date"
                      value={courseDetails?.enrollment_date}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group controlId="program" className="mt-3">
                    <Form.Label>Program</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your program name"
                      name="program"
                      value={courseDetails?.program}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group controlId="preferred_centre" className="mt-3">
                    <Form.Label>Preferred Centre</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your preferred centre"
                      name="preferred_centre"
                      value={courseDetails?.preferred_centre || "Not provided"}
                      onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="student_batch_name"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Student Batch</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your batch"
                      name="student_batch_name"
                      value={courseDetails?.student_batch_name}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="scheme"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Scheme</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your scheme"
                      name="scheme"
                      value={courseDetails?.scheme || "Not Provided"}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="hosteller_or_day_scholar"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Hosteller or Day Scholar</Form.Label>
                    <Form.Control
                      //   as="select"
                      type="text"
                      placeholder="Enter your stay"
                      name="hosteller_or_day_scholar"
                      value={courseDetails?.hosteller_or_day_scholar}
                      disabled
                      //   onChange={handleChange}
                    >
                      {/* <option value="" disabled>
                              Not set
                            </option>
                            <option value="Hosteller">Hosteller</option>
                            <option value="Day Scholar">Day Scholar</option> */}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="hostel"
                    className="col-sm-12 col-md-6 mt-3"
                  >
                    <Form.Label>Hostel</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your hostel"
                      name="hostel"
                      value={courseDetails?.hostel || "Not Provided"}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="new_offered_amount"
                    className="col-sm-12 col-md-6  mt-3"
                  >
                    <Form.Label>Offered Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter offered amount"
                      name="new_offered_amount"
                      value={courseDetails?.new_offered_amount}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="total_course_fee_paid"
                    className="col-sm-12 col-md-6  mt-3"
                  >
                    <Form.Label>Total Course Fee Paid</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter total course fee paid"
                      name="total_course_fee_paid"
                      value={courseDetails?.total_course_fee_paid}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="course_fee_balance"
                    className="col-sm-12 col-md-6  mt-3"
                  >
                    <Form.Label>Course Fee Balance</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter course fee balance"
                      name="course_fee_balance"
                      value={courseDetails?.course_fee_balance}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="total_hostel_fee_paid"
                    className="col-sm-12 col-md-6  mt-3"
                  >
                    <Form.Label>Total Hostel Fee Paid</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter total hostel fee paid"
                      name="total_hostel_fee_paid"
                      value={courseDetails?.total_hostel_fee_paid}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="total_transportation_fee_paid"
                    className="col-sm-12 col-md-6  mt-3"
                  >
                    <Form.Label>Total Transportation Paid</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter total transportation paid"
                      name="total_transportation_paid_"
                      value={courseDetails?.total_transportation_paid_}
                      //   onChange={handleChange}
                      disabled
                    />
                  </Form.Group>
                </Row>

                {/* <Button variant="success" type="submit" className="btn save-button mt-3">
                  SAVE
                </Button> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
